<template>
	<div class="dashboard-card">
		<apex-chart :options="options" :series="series" />
	</div>
</template>

<script>
import moment from 'moment'
import ApexChart from 'vue-apexcharts'

/**
 * Days of week.
 *
 * @type {Array}
 */
const days = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]

/**
 * The default chart options.
 *
 * @return {Object}
 */
const getDefaultChartOptions = () => {
	return {
		chart: {
			type: 'bar',
			stacked: true,
		},
		colors: ['#f7992b', '#2b6cb0'],
		plotOptions: {
			bar: {
				columnWidth: '55%',
				endingShape: 'rounded',
				horizontal: false,
			},
		},
		stroke: {
			width: 1,
			colors: ['#fff'],
		},
		title: {
			text: 'Call Volume by Day',
			style: {
				fontFamily: 'Roboto',
			},
		},
		subtitle: {
			text: 'Last 30 days',
			align: 'left',
			style: {
				fontSize: '12px',
				fontWeight: 'normal',
				fontFamily: 'Roboto',
				color: '#9699a2',
			},
		},
		xaxis: {
			categories: days,
			style: {
				fontFamily: 'Roboto',
			},
		},
		fill: {
			opacity: 1,
		},
		legend: {
			position: 'top',
			horizontalAlign: 'left',
			offsetX: 40,
		},
	}
}

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ApexChart,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the chart's options.
		 *
		 * @return {Object}
		 */
		options() {
			const options = getDefaultChartOptions()

			if (!this.title) {
				return options
			}

			options.subtitle.text = this.title

			return options
		},

		/**
		 * Get the chart's data to display.
		 *
		 * @return {Array}
		 */
		series() {
			return [
				{
					name: 'Urgent calls',
					data: this.getWeekly().map(day => day.urgent_count),
				},
				{
					name: 'Non-Urgent calls',
					data: this.getWeekly().map(day => day.non_urgent_count),
				},
			]
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Calculate the calls average.
		 *
		 * @param {Object} day
		 * @return {Object}
		 */
		getDayAverage(day) {
			day.average_count = (day.total_count / day.day_count || 0).toFixed(
				2
			)

			return day
		},

		/**
		 * Get the days of the week array.
		 *
		 * @return {Array}
		 */
		getWeekDays() {
			return days.map(day => {
				return {
					urgent_count: 0,
					non_urgent_count: 0,
					total_count: 0,
					day: day,
					day_count: 0,
					average_count: 0,
				}
			})
		},

		/**
		 * The weekly information to be displayed.
		 *
		 * @return {Array}
		 */
		getWeekly() {
			return this.groupWeekDays().map(this.getDayAverage)
		},

		/**
		 * The all the calls grouped by the of the week.
		 *
		 * @return {Array}
		 */
		groupWeekDays() {
			const weekDays = this.getWeekDays()

			return this.daily.reduce((accu, day) => {
				const dayNumber = moment(day.date).day()
				const { urgent_count, non_urgent_count, total_count } = day

				accu[dayNumber].day_count++
				accu[dayNumber].urgent_count += urgent_count
				accu[dayNumber].non_urgent_count += non_urgent_count
				accu[dayNumber].total_count += total_count

				return accu
			}, weekDays)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'WeeklyChart',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The daily information to be displayed.
		 *
		 * @type {Array}
		 */
		daily: {
			type: Array,
			required: true,
		},

		/**
		 * The chart's title.
		 *
		 * @type {String}
		 */
		title: {
			type: String,
			required: false,
		},
	},
}
</script>
