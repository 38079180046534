<template>
	<div class="dashboard-card">
		<apex-chart :options="options" :series="series" />
	</div>
</template>

<script>
import ApexChart from 'vue-apexcharts'

/**
 * The default chart options.
 *
 * @return {Object}
 */
const getDefaultChartOptions = () => {
	return {
		chart: {
			type: 'bar',
			stacked: true,
		},
		colors: ['#f7992b', '#2b6cb0'],
		plotOptions: {
			bar: {
				columnWidth: '30%',
				barHeight: '75%',
				endingShape: 'rounded',
				horizontal: true,
			},
		},
		stroke: {
			width: 1,
			colors: ['#fff'],
		},
		title: {
			text: 'Completed by',
			style: {
				fontFamily: 'Roboto',
			},
		},
		subtitle: {
			text: 'Last 30 days',
			align: 'left',
			style: {
				fontSize: '12px',
				fontWeight: 'normal',
				fontFamily: 'Roboto',
				color: '#9699a2',
			},
		},
		xaxis: {
			categories: [],
			style: {
				fontFamily: 'Roboto',
			},
		},
		fill: {
			opacity: 1,
		},
		legend: {
			position: 'top',
			horizontalAlign: 'left',
			offsetX: 40,
		},
	}
}

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ApexChart,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the chart categories to be displayed.
		 *
		 * @return {Array}
		 */
		categories() {
			if (!this.completed) {
				return []
			}

			return this.completed.map(type => type.label || 'N/A')
		},

		/**
		 * Get the chart's options.
		 *
		 * @return {Object}
		 */
		options() {
			const options = getDefaultChartOptions()

			if (this.title) {
				options.subtitle.text = this.title
			}

			options.xaxis.categories = this.categories

			return options
		},

		/**
		 * Get the chart's data to display.
		 *
		 * @return {Array}
		 */
		series() {
			if (!this.completed) {
				return []
			}

			return [
				{
					name: 'Call completed',
					data: this.completed.map(type => type.count),
				},
			]
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CompletedChart',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The completed calls grouped by user to be displayed.
		 *
		 * @type {Array}
		 */
		completed: {
			type: Array,
			required: true,
		},

		/**
		 * The chart's title.
		 *
		 * @type {String}
		 */
		title: {
			type: String,
			required: false,
		},
	},
}
</script>
