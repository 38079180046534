<template>
	<div class="dashboard-card">
		<apex-chart
			ref="chart"
			height="100%"
			:options="options"
			:series="series"
		/>
	</div>
</template>

<script>
import ApexChart from 'vue-apexcharts'

/**
 * The default chart options.
 *
 * @return {Object}
 */
const getDefaultChartOptions = () => {
	return {
		chart: {
			type: 'bar',
			stacked: true,
		},
		colors: ['#f7992b', '#2b6cb0'],
		plotOptions: {
			bar: {
				columnWidth: '55%',
				endingShape: 'rounded',
				horizontal: false,
			},
		},
		stroke: {
			width: 1,
			colors: ['#fff'],
		},
		title: {
			text: 'Call Volume by Hour',
			style: {
				fontFamily: 'Roboto',
			},
		},
		subtitle: {
			text: 'Last 30 days',
			align: 'left',
			style: {
				fontSize: '12px',
				fontWeight: 'normal',
				fontFamily: 'Roboto',
				color: '#9699a2',
			},
		},
		xaxis: {
			categories: [],
			labels: {
				rotate: -45,
				rotateAlways: true,
			},
			style: {
				fontFamily: 'Roboto',
			},
		},
		fill: {
			opacity: 1,
		},
		legend: {
			position: 'top',
			horizontalAlign: 'left',
			offsetX: 40,
		},
	}
}
export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ApexChart,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the chart's options.
		 *
		 * @return {Object}
		 */
		options() {
			const options = getDefaultChartOptions()

			if (this.title) {
				options.subtitle.text = this.title
			}

			options.xaxis.categories = this.hourly.map(item => item.label)

			return options
		},

		/**
		 * Get the chart's data to display.
		 *
		 * @return {Array}
		 */
		series() {
			return [
				{
					name: 'Urgent calls',
					data: this.hourly.map(hour => hour.urgent_count),
				},
				{
					name: 'Non-Urgent calls',
					data: this.hourly.map(hour => hour.non_urgent_count),
				},
			]
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'HourlyChart',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The daily information to be displayed.
		 *
		 * @type {Array}
		 */
		hourly: {
			type: Array,
			required: true,
		},

		/**
		 * The chart's title.
		 *
		 * @type {String}
		 */
		title: {
			type: String,
			required: false,
		},
	},
}
</script>
